var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-sheet theme--light br-0",attrs:{"elevation":1}},[_c('v-card-title',[_c('v-row',{staticClass:"col-md-12"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_vm._v(" Socios implementadores ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{staticClass:"mb-2 float-right light-blue-502",attrs:{"color":"","dark":""},on:{"click":_vm.modalNuevo}},[_vm._v(" Registrar socio ")])],1)],1),_c('v-row',{staticClass:"col-md-12 m-0 p-0"},[_c('v-divider')],1)],1),_c('v-card-text',{staticClass:"pl-0 pr-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.sociosImplementadores,"search":_vm.filtro,"loading":_vm.tableSociosLoading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","xs":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","filled":"","color":"blue-grey lighten-2","label":"Nombre de la unidad ejecutora o institución","maxlength":"200"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","xs":"6"}},[_c('v-select',{attrs:{"items":_vm.estados,"loading":false,"dense":"","filled":"","label":"Estado","no-data-text":'No existen estados registrados',"menu-props":"auto","return-object":""},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"cols":"2","md":"2","sm":"12","xs":"6"}},[_c('v-btn',{staticClass:"ma-0 white--text d-none",attrs:{"medium":"","color":"light-blue darken-2"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.socio))]),_c('td',[_vm._v(_vm._s(item.tipoSocio))]),_c('td',[_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"label":"","color":item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4',"text-color":item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[_c('v-btn',{staticClass:"\n                    ma-2\n                    btn-bg-light\n                    blue-grey--text\n                    lighten-2--text\n                    font-weight-medium\n                    text-capitalize\n                  ",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.obtenerDatosItem(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Actualizar ")],1)],1)])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"800","transition":"scroll-y-transition","persistent":""},model:{value:(_vm.dialogRegistro),callback:function ($$v) {_vm.dialogRegistro=$$v},expression:"dialogRegistro"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.accion===2 ? ("Actualizar | " + _vm.nombreSocio) : "Registrar socio")+" "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
              _vm.resetForm();}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticClass:"d-none"}),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"pt-4 pl-2 pr-2",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","loading":_vm.ddTiposSocioImplementador,"items":_vm.tiposSocioImplementador,"color":"blue-grey lighten-2","label":"Tipo de socio implementador","item-text":"tipoSocio","item-value":"id","rules":[_vm.selectRequired('tipo de socio')],"menu-props":"auto"},model:{value:(_vm.datosItem.tipoSocioImplementadorId),callback:function ($$v) {_vm.$set(_vm.datosItem, "tipoSocioImplementadorId", $$v)},expression:"datosItem.tipoSocioImplementadorId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","filled":"","color":"blue-grey lighten-2","label":"Nombre del socio","rules":[
                                _vm.required('Nombre del socio'),
                                _vm.minLength('Nombre del socio', 5),
                                _vm.maxLength('Nombre del socio', 250)
                            ],"maxlength":"250"},model:{value:(_vm.datosItem.socio),callback:function ($$v) {_vm.$set(_vm.datosItem, "socio", $$v)},expression:"datosItem.socio"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text mb-2 float-right ml-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.accion===1 ? "Registrar socio" : "Actualizar socio")+" ")]),_c('v-btn',{staticClass:"mb-2 float-right grey lighten-5",attrs:{"color":"grey lighten-5","elevation":"0","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
                                _vm.resetForm();}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)],1),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}}),_c('SnackAlert',{ref:"snackalert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }