<template lang="">
 <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Socios implementadores
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color=""
              dark
              class="mb-2 float-right light-blue-502"
              @click="modalNuevo"
            >
              Registrar socio
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">


        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="sociosImplementadores"
          :search="filtro"
          :loading="tableSociosLoading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Nombre de la unidad ejecutora o institución"
                    maxlength="200"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.socio }}</td>
              <td>{{ item.tipoSocio }}</td>
             <!-- <td>
                {{
                  item.nombreMaximaAutoridad != null
                    ? item.nombreMaximaAutoridad
                    : ""
                }}
              </td>-->
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id)"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>                
              </td>
            </tr>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>


    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="800"
      transition="scroll-y-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          {{accion===2 ? `Actualizar | ${nombreSocio}` : `Registrar socio`}}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text>

            <v-form
                ref="form"
                v-on:submit.prevent="registrarItem"
                v-model="validForm"
                class="pt-4 pl-2 pr-2"
                >
                
                <v-row>
                    <!--inicio:: campo -->
                    <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                        <v-select
                            dense
                            filled
                            v-model="datosItem.tipoSocioImplementadorId"
                            :loading="ddTiposSocioImplementador"
                            :items="tiposSocioImplementador"
                            class="required"
                            color="blue-grey lighten-2"
                            label="Tipo de socio implementador"
                            item-text="tipoSocio"
                            item-value="id"
                            :rules="[selectRequired('tipo de socio')]"
                            menu-props="auto"
                        >
                        </v-select>
                    </v-col>
                    <!--fin:: campo-->

                
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-text-field
                            autocomplete="off"
                            class="required"
                            dense
                            filled
                            color="blue-grey lighten-2"
                            v-model="datosItem.socio"
                            label="Nombre del socio"
                            :rules="[
                                required('Nombre del socio'),
                                minLength('Nombre del socio', 5),
                                maxLength('Nombre del socio', 250)
                            ]"
                            maxlength="250"
                        ></v-text-field>
                        </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12" class="pb-0">
                        <v-spacer></v-spacer>
                        <!--:disabled="!validDocForm" type="submit"-->
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right ml-2"
                            type="submit"
                            :elevation="0"
                            :disabled="!validForm"
                            :loading="btnRegistroLoading"
                        >
                            {{accion===1 ? `Registrar socio` : `Actualizar socio`}}
                        </v-btn>

                        <v-btn
                            color="grey lighten-5"
                            elevation="0"
                            class="mb-2 float-right grey lighten-5"
                            @click="
                                dialogRegistro = false;
                                resetForm();
                            "
                            :disabled="btnRegistroLoading"
                        >
                            Cancelar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
    </v-dialog>


    <!--end:: modal registro-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";

import { OBTENER_SOCIOS_IMPLEMENTADORES_CSS, REGISTRAR_SOCIO_IMPLEMENTADOR_CSS, ACTUALIZAR_SOCIO_IMPLEMENTADOR_CSS, OBTENER_SOCIO_IMPLEMENTADOR_CSS } from "@/core/services/store/sociosimplementadores/socioimplementador.module";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
export default {
    name: "AdminSociosImplementadores",
    components: {
        DialogLoader,
        SnackAlert
    },
    data() {
        return { 
            btnRegistroLoading: false,
            validForm: false,
            sociosImplementadores:[],
            tableSociosLoading: false,
            filtro: "",
            accion: 1,
            dialogLoaderVisible: false,
            dialogLoaderText: "",
            nombreSocio: "",
            dialogRegistro: false,
            tiposSocioImplementador: [],
            ddTiposSocioImplementador: false,
            datosItem: {
                socio: "",
                tipoSocioImplementadorId: 0,
                estadosId: 1,
                usuarioCreacion: "admin"
            },
            estado: "",
             estados: [
                { text: "Todos", value: null },
                { value: 1, text: "Activo" },
                { value: 2, text: "Inactivo" }
            ],
            ...validations
        }
    },

    methods: {

        filtroEstado(value) {
            // If this filter has no value we just skip the entire filter.
            if (!this.estado.value) {
                return true;
            }
            // Check if the current loop value (The calories value)
            // equals to the selected value at the <v-select>.
            return value === this.estado.text;
        },

        async obtenerItems(){
            return 0;
        },


        modalNuevo(){
            this.accion=1;
            this.resetForm();
            this.dialogRegistro=true;
        },

        resetForm(){
            this.datosItem= {
                socio: "",
                tipoSocioImplementadorId: 0,
                estadosId: 1,
                usuarioCreacion: "admin"
            };
        },


        async obtenerSociosImplementadores(){
            
            this.tableSociosLoading = true;
            this.sociosImplementadores = [];
            await this.$store
                .dispatch(OBTENER_SOCIOS_IMPLEMENTADORES_CSS, {estadoId: 1})
                .then(res => {
                    if (res.status === 200) {
                        this.sociosImplementadores = res.data;
                    }
                    this.tableSociosLoading = false;
                })
                .catch(() => {
                    this.sociosImplementadores = [];
                    this.tableSociosLoading = false;
                });
        },


        //Obtener los niveles institucion
        async obtenerTiposSocio() {
            this.ddTiposSocioImplementador = true;
            this.tiposSocioImplementador = [];
            this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TipoSocioImplementador/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.tiposSocioImplementador = res.data;
                }
                this.ddTiposSocioImplementador = false;
            })
            .catch(() => {
                this.tiposSocioImplementador = [];
                this.ddTiposSocioImplementador = false;
            });
        },


        //Registrar un socio implementador
        async registrarItem(){
            this.btnRegistroLoading = true;

            //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
            /* this.switchItemEstado
                ? (this.datosItem.estadosId = 1)
                : (this.datosItem.estadosId = 2); */

            this.datosItem.estadosId = 1;
            
            let dispatch = REGISTRAR_SOCIO_IMPLEMENTADOR_CSS;

            //?Si accion es 1 se realiza el registro si es 2 la actualización
            if(this.accion === 2){
                dispatch = ACTUALIZAR_SOCIO_IMPLEMENTADOR_CSS;
            } 

            await this.$store
            .dispatch(dispatch, this.datosItem)
            .then(res => {
                if (res.status === 200) {
                    //this.nombreInstitucion = this.datosItem.nombreInstitucion;
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                    this.obtenerSociosImplementadores();
                    this.dialogRegistro = false;

                    //this.resetForm();
                    //this.tab = "tab-2";
                    //this.accion = 2;
                    this.tabContactosDisabled=false;
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                this.btnRegistroLoading = false;
            });
            
        },


        //!Obtener datos de un item especifico
        async obtenerDatosItem(Id) {
        
            this.dialogLoaderVisible = true;
            this.dialogLoaderText = "Obteniendo información...";
           
            
            this.btnRegistroText = `Actualizar`;
            this.datosItem={};
            await this.$store
                .dispatch(OBTENER_SOCIO_IMPLEMENTADOR_CSS, Id)
                .then(res => {
                  if (res.status === 200) {
                      this.datosItem = res.data
                      this.datosItem.estadosId === 1
                      /* ? (this.switchItemEstado = true)
                      : (this.switchItemEstado = false); */
                      this.accion = 2;
                      this.dialogRegistro = true;
                  } else {
                      this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`);
                  }
                  this.dialogLoaderVisible = false;
                })
                .catch(() => {
                    //console.log(error)
                    this.dialogLoaderVisible = false;
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`);
                
                });
        },

    },

    created(){
        this.obtenerSociosImplementadores();
        this.obtenerTiposSocio();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Inicio", route: "dashboard" },
        { title: "Administracion" },
        { title: "Socios Implementadores" }
        ]);
    }, 


    computed:{

        headers() {
            return [
            {
                text: "Id",
                align: "d-none",
                sortable: false,
                value: "id"
            },
            {
                text: "Socio implementador",
                align: "start",
                sortable: true,
                value: "socio"
            },
            {
                text: "Tipo de socio",
                align: "start",
                sortable: true,
                value: "tipoSocio"
            },
           /*  {
                text: "Máxima autoridad",
                align: "start",
                sortable: true,
                value: "institucion"
            }, */
            {
                text: "Estado",
                align: "start",
                sortable: true,
                value: "estado",
                filter: this.filtroEstado
            },
            {
                text: "Acciones",
                align: "start",
                sortable: false,
                value: ""
            }
        ]
        }, 
    }

}
</script>
<style lang="">
    
</style>